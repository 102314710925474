import tableStore from '@/store/modules/table';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ScreenText } from '@/lang/ScreenText';
import accountListStore from '@/store/modules/accountsList';
import manageListStore from '@/store/modules/manageList';
import APP_CONST from '@/constants/AppConst';
import TableComponent from '@/commoncomponents/tablecomponent/TableComponent.vue';
import ResetPasswordPopup from '@/commoncomponents/ResetPasswordPopup/ResetPasswordPopup.vue';
import APP_UTILITIES from '@/utilities/commonFunctions';
import CreateUpdateComponent from '@/popupcomponents/createupdateuser/CreateUpdateComponent.vue';
import InvitationSentComponent from '@/popupcomponents/invitationcomponent/InvitationComponent.vue';
import { roles, ICreateUser, IAccount } from '@/Model/programModel';
import { getAccounts, resendInvite, deleteUser, getProgramByAccount } from '@/services/create-user/createUserService';
import ReadOnlyUserDetails from '@/popupcomponents/readonlyuserdetails/ReadonlyUserDetails.vue';
import createUpdatePartner from '@/canvas-components/createupdatepartneruser/createUpdatePartner.vue';
import { getUserDetailsResponseObj, getUserInfomartionByUserId, bulkResetPassword } from '@/services/userService/users-api';
import UIkit from 'uikit';
import { getModule } from 'vuex-module-decorators';
import { GlobalModule } from '@/store/global/globalModule';
import store from '@/store';
import PopupWrapperComponent from '@/commoncomponents/popupwrapper/PopupWrapperComponent.vue';
import { getClassroomsBySite } from '@/services/create-user/createUserService';
import SmartTableContainer from '@/commoncomponents/smartTableComponents/SmartTableContainer.vue';
import ActionsDropdown from '@/commoncomponents/actionsdropdowncomponent/ActionsDropdown.vue';
import programList from '@/store/modules/programList';
import BouncingPreloaderComponent from '@/commoncomponents/bouncingpreloadercomponent/BouncingPreloaderComponent.vue';
import ActionIgnoredPopup from '@/popupcomponents/actionIgnoredPopup/ActionIgnoredPopup.vue';
import programListStore from '@/store/modules/programList';
import { SearchObj, SortObjc } from '@/Model/model';

@Component({
  components: {
    'bouncing-preloader': BouncingPreloaderComponent,
    'account-table': SmartTableContainer,
    'manage-users': TableComponent,
    'add-user': CreateUpdateComponent,
    'add-partner-user': createUpdatePartner,
    'invite-sent': InvitationSentComponent,
    'popup-wrapper': PopupWrapperComponent,
    'read-user-detail': ReadOnlyUserDetails,
    'actions-dropdown': ActionsDropdown,
    'action-ignored-popup': ActionIgnoredPopup,
    'reset-password': ResetPasswordPopup
  }
})
export default class AccountListComponent extends Vue {
    private objScreenText: ScreenText = new ScreenText();
    public account_header: any = APP_CONST.ACCOUNTS_HEADER;
    public account_header_fixed: any = this.account_header.FIXED;
    public manage_users_header: any = APP_CONST.MANAGE_USERS_BX_HEADER;
    public manage_sort_list: any = APP_CONST.MANAGE_USERS_SORT_LIST;
    public key: any = APP_CONST.ACCOUNT_KEY;
    public manage_key: any = APP_CONST.MANAGE_BX_KEY;
    public actions: any = APP_CONST.ACCOUNT_ACTION;
    public account_list: any = '';
    public manage_list: any = '';
    public total_items: number = -1;
    public search: string = '';
    public componentRender: string = '';
    public roleType: any = 1;
    public tabSelected: string = 'bellxcel-users';
    public requiredIds: roles = { roleId: 1 } as roles;
    public userDetails: ICreateUser = JSON.parse(JSON.stringify(APP_CONST.USER_CREATION)) as ICreateUser;
    public inviteText: string = '';
    public pointerEventNone: boolean = false;
    public user: any = {};
    public isSearching:boolean= false;
    public hoverText:string='';
    public hoverStyleObj: any= {};
    sortField: string = '';
    sortDir: number = 1;
    initialPage: boolean | null = false;
    getGlobalState = getModule(GlobalModule, store);
    private authKey:string = '';
    private manageUserPayload : any = APP_CONST.MANAGE_PAYLOAD;
    public closeResendModal:boolean=false;
    public allAccounts: IAccount[] = [] as IAccount[];
    public accountFilterDropdown: boolean = false;
    public accountFilterInput: string = '';
    public filteredByAccount: string = '';
    public filteredByAccountId: number = 0;
    public hoverId: number = 0;
    partnerUserDetails = {userRole: [] as any};    
    public isMobileView: boolean = false;
    private openDropStatus: boolean = false;
    public page: number= 1;
    public displayColumn:any = [];
    public isResizer:boolean = true; 
    public isSortingSearching:boolean = true;
    public accountsSearchObj: any = { accountName: '', accountStatus: '', contractedScholars: '', contractedScholarsUsed: '', programCount: '', primaryAccountAdminFullName: '', bxPartnerLeadFullName: '', bxSuccessLeadFullName: '' };
    public actionsDropdown: any = APP_CONST.ACCOUNTS_ACTIONS_DROPDOWN;
    public searchLists: any = <any>[{ header: 'accountStatus', values: ['Active', 'Inactive'] }];
    public ignoredActionAccounts: any[] = [];
    public popupInstructions: {} = {first:'The action was NOT completed for the below', second: 'accounts as they are ineligible for the action.'};
    public displayAll: boolean = true;
    public initialTotalItems:number = 0;
    public localConst:any = APP_CONST;
    public fullListAccountData:any = [];
    public selectedUser:{userId:number; name:string|null} = {userId: 0, name: ''};
    public resetPasswordTimer:any =0;

    public getScreenText(key: string): string {
      return this.objScreenText.getScreenText(key);
    }

    @Prop()
    roleId!: number;

    @Prop()
    currentRoleId!: number;
    

    /* istanbul ignore next */
    get accounts() {
      return accountListStore.accountsdata;
    }

    /* istanbul ignore next */
    get accountResizesData() {
      return tableStore.accountResizesData;
    }

    /* istanbul ignore next */
    get accountPayload(){
      return accountListStore.accountPayload;
    }

    /* istanbul ignore next */
    get allAccountDataSelected() {
      return tableStore.allDataSelected;
    }

    /* istanbul ignore next */
    get accountAllListData() {
      return accountListStore.accountAllListData;
    }

    /* istanbul ignore next */
    get isLoaderVisible(){
      return (this.initialTotalItems>=this.localConst.HUNDRED || this.initialTotalItems <= this.localConst.ZERO);
    }

    /* istanbul ignore next */
    created() {
      accountListStore.mutateAccountPayload(JSON.parse(JSON.stringify(APP_CONST.ACCOUNT_PAYLOAD)));
      tableStore.mutateFilteredObject(JSON.parse(JSON.stringify(APP_CONST.ACCOUNTS_HEADER.MOVABLE)));   
    }

    clearSearch(arg:string) {
      this.search = '';
      this.isSearching = false;
      if(arg == 'manage-users'){
        this.manageList({ page: 1, sortField: '', sortDir: 1 });  
      }
      else{
        accountListStore.mutateAccountPayload({...accountListStore.accountPayload,page:1,search:'',searchField:''});
        accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload,page:1,search:'',searchField:''});
        this.total_items = this.isLoaderVisible
          ? -1
          : this.total_items;
        this.clearSelection();
        this.accountListFullData().then(() => { 
          this.accountList();
        });
      }
    }

    handleListTypeSelection(type:string){
      this.manage_list = [];
      this.total_items = 0;
      this.search = '';
      if(type=='bellxcel-users'){
        this.manage_users_header = APP_CONST.MANAGE_USERS_BX_HEADER;
        this.manage_key=APP_CONST.MANAGE_BX_KEY;
        this.$store.dispatch('globalModule/resetPageCount', true);
        this.tabSelected = type;
        this.roleType = 1;
        this.requiredIds.roleId = 1;
        this.manageList({page: 1, sortField: '', sortDir: 1}, true);
        this.closeResendModal=false;
        this.$store.dispatch('globalModule/updateDeleteUserStatus', false);
      }
      else{
        this.resetAccountDropdown();
        this.manage_users_header= APP_CONST.MANAGE_USERS_PARTNER_HEADER;
        this.manage_key=APP_CONST.MANAGE_PARTNER_KEY;
        this.$store.dispatch('globalModule/resetPageCount', true);
        this.tabSelected = type;
        this.roleType = 2;
        this.requiredIds.roleId = 2;
        this.getSortingDefaultManageUser();
        this.closeResendModal=false;
        this.$store.dispatch('globalModule/updateDeleteUserStatus', false);
      }
    }

    async accountListFullData(){
      this.clearSelection();
      await accountListStore.allAccountData(accountListStore.fullAccountsPayload);
    }

    async accountList(isSearching?:boolean) {
      if (APP_UTILITIES.getFilteredColumns(`${ this.authKey}-AccountsResizeData`)) {
        let accountsResizeData: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-AccountsResizeData`);
        accountsResizeData = JSON.parse(accountsResizeData);
        tableStore.mutateResizeObject(accountsResizeData);
      } 
      if (isSearching && (!this.search.trim() || this.search.trim().length >=3)) {
        this.total_items = this.isLoaderVisible
          ? -1
          : this.total_items;
        this.accountsSearchObj = { accountName: '', accountStatus: '', contractedScholars: '', contractedScholarsUsed: '', programCount: '', primaryAccountAdminFullName: '', bxPartnerLeadFullName: '', bxSuccessLeadFullName: '' };
        accountListStore.mutateAccountPayload({...accountListStore.accountPayload,searchField:'', page:APP_CONST.ONE, search:this.search});
        accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload,searchField:'', page:APP_CONST.ONE, search:this.search});
        this.accountListFullData().then(() => {});
      }       
      await accountListStore.getAccountList(accountListStore.accountPayload);
      if (isSearching) {
        this.clearSelection();
      }
      this.CheckAccountListAPIRepsonse(accountListStore.accountPayload);
    }

    private CheckAccountListAPIRepsonse(payload:any) {
      if (accountListStore.accountsdata.status === APP_CONST.RESPONSE_200) {
        const listOfAccount: any = accountListStore.accountsdata.data.results;
        this.total_items = accountListStore.accountsdata.data.count;
        this.account_list = listOfAccount;
        this.account_list && this.account_list.length && this.account_list.forEach((account: any) => {
          tableStore.allDataSelected && tableStore.allDataSelected.length && tableStore.allDataSelected.forEach((elementChecked: any) => {
            if (elementChecked.accountId === account.accountId) {
              account.selected = elementChecked.selected;
            }
          });
        });        
        if (tableStore.allSelect){
          this.account_list 
                    && this.account_list.length 
                        && this.account_list.forEach((account: any) => {
                          account.selected = true;
                        });
          this.account_list
                    && this.account_list.length
                    && this.account_list.forEach((account: any) => {
                      tableStore.unSelectData && tableStore.unSelectData.length && tableStore.unSelectData.forEach((elementChecked: any) => {
                        if (elementChecked.accountId === account.accountId) {
                          account.selected = false;
                        }
                      });
                    });
        }
        this.isSearching = false;
      }
      else {           
        this.account_list = [];
        this.total_items = 0;
        this.isSearching = payload.search
          ? true
          : false;
      }
    }

    clearSelection(){
      tableStore.mutateSelectAll(false);
      tableStore.mutateFewCheckData('');
      tableStore.mutateAllSelectedData([]);
      tableStore.mutateUnSelectedData([]);
      tableStore.mutateSelectStatus('');
      const element = <HTMLInputElement>document.getElementById('all-data-checkbox');
      if (element) {
        element.checked = false;
      }
      this.account_list && this.account_list.length && this.account_list.forEach((element: any) => {
        element.selected = false;
      });
    }

    async manageList(event: { page: number; sortField: string; sortDir: number }, state?: boolean) {  
      const payload = this.roleId== APP_CONST.FOUR
        ? APP_CONST.MANAGE_PROGRAM_PAYLOAD
        :  APP_CONST.MANAGE_PAYLOAD;
      if(APP_UTILITIES.mobileAndTabletCheck()){
        payload.count = 5;
      }
      if(this.isMobileView){
        const partnerSearchBox = this.$refs.partnerSearchBox as any;
        /* istanbul ignore else */
        if(partnerSearchBox){
          partnerSearchBox.blur();
        }
        this.closeSearchPopup();
      }
      const storedAccountId:any = APP_UTILITIES.getCookie('user_role');
      if(event.sortField == ''){
        for(const el of this.manage_users_header) {
          /* istanbul ignore else */
          if(el.order > 0) {
            event.sortField = el.value;
            event.sortDir = el.order;
            break;
          } 
        }
      }
      const accountId = (this.tabSelected === 'bellxcel-users')
        ? 0
        : this.filteredByAccountId;
      payload.id=this.roleId== APP_CONST.FOUR
        ? JSON.parse(storedAccountId)[0].accountId
        : accountId;
      payload.page = event.page
        ? event.page
        : 1;
      payload.search = this.search;
      payload.sortField = event.sortField
        ? event.sortField
        : this.manageUserPayload.sortField;
      payload.sortDir = event.sortDir
        ? event.sortDir
        : this.manageUserPayload.sortDir;
      payload.roleType = this.roleId== APP_CONST.FOUR
        ? APP_CONST.TWO
        : this.roleType;
      payload.ProgramId = this.roleId== APP_CONST.FOUR
        ? this.filteredByAccountId
        : 0;
      await manageListStore.getManageList(payload); 
      this.CheckManageListAPIRepsonse(state, payload);
      this.manageUserPayload = JSON.parse(JSON.stringify(payload));
      this.setSortingDefaultManageUser();
      this.closeSearchPopup();
    }

    private CheckManageListAPIRepsonse(state: boolean | undefined, payload: { search: string; roleType: number; page: number; count: number; sortField: string; sortDir: number }) {
      /* istanbul ignore else */
      if (manageListStore.managedata.status === APP_CONST.RESPONSE_200) {
        this.initialPage = state
          ? state
          : null;
        const listOfAccount: any = manageListStore.managedata.data.results;
        this.total_items = manageListStore.managedata.data.count;
        const listOnSearch = payload.search
          ? APP_CONST.NO_MANAGE_USER_FOUND
          : APP_CONST.NO_USER_LIST_FOUND;
        this.manage_list = listOfAccount.length
          ? listOfAccount
          : listOnSearch;
        this.isSearching = false;
      }
      else {
        this.manage_list = [];
        this.total_items = 0;
        this.isSearching = payload.search
          ? true
          : false;
      }
    }

    async searchList() {
      /* istanbul ignore else */
      if (this.search) {
        await accountListStore.searchAccountList(this.search);
        this.account_list = accountListStore.searchdata.items;
        this.total_items = accountListStore.searchdata.total_items
          ? accountListStore.searchdata.total_items
          : 0;
      }
    }

    addAccount() {
      this.$router.push('/accounts/create');
    }

    async enableDisableAccount(status:number){
      const payload:any = {
        selectAllPayload:{
          page:null,
          count:null,
          id:1,
          active: 0,
          sortField: accountListStore.accountPayload.sortField,
          sortDir: accountListStore.accountPayload.sortDir,
          searchField: accountListStore.accountPayload.searchField,
          search: accountListStore.accountPayload.search,
          selectedIds:[],
          notSelectedIds:[],
          isAllSelected: tableStore.selectAll,
          isExactMatch: accountListStore.accountPayload.isExactMatch
        },
        status,
      };
      let unSelectedIds:any = [];
      if (tableStore.selectAll) {
        if (tableStore.unSelectData.length) {
          unSelectedIds = tableStore.unSelectData.map((account: any) => {
            return account.accountId;
          });
        }
      }
      payload.selectAllPayload.notSelectedIds = unSelectedIds;
        
      const accountUsersIds:any = this.allAccountDataSelected.map((account:any) => {
        return account.accountId;
      });
      payload.selectAllPayload.selectedIds = accountUsersIds;
      this.ignoredActionAccounts = [];
      accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload, page: APP_CONST.ONE, search: decodeURIComponent(accountListStore.accountPayload.search) });
      this.total_items = -1;
      await accountListStore.enableDisableAccount(payload).then((response:any) =>{
        if (response.status == APP_CONST.RESPONSE_200) {
          this.ignoredActionAccounts= response && response.data;
          if( this.ignoredActionAccounts.length && UIkit.modal('#actions-ignored-popup')) {
            UIkit.modal('#actions-ignored-popup').show();
            const elem : any = document.getElementById('popup-content') ;
            elem.scrollTo(0, 0);
          }
          this.clearSelection();
          this.accountListFullData().then(() => {
            this.accountList();
          });
        }
      });
    }

    closeActionIgnoredPopup(){
      if(UIkit.modal('#actions-ignored-popup')) {
        UIkit.modal('#actions-ignored-popup').hide();
      }
      this.ignoredActionAccounts = [];
    }

    performAction(key: string) {
      switch (key) {
        case 'Add':
          this.addAccount();
          break;
        case 'Disable':
          this.enableDisableAccount(APP_CONST.TWO);
          break;
        case 'Enable':
          this.enableDisableAccount(APP_CONST.ONE);
          break;
        default:
          break;
      }
    }

    updateList(res: any) {
      /* istanbul ignore else */
      if (res.status == APP_CONST.TRUE) {            
        this.manageList({ page: this.page, sortField: '', sortDir: 1 });
        /* istanbul ignore else */
        if(res.update) {
          this.inviteText = APP_CONST.USERS_INVITATION_BODY;
          setTimeout(() => {
            UIkit.modal('#modal-invitation-sent').show();
          }, 500);
        }
      }
    }
    isMobile(){
      this.isMobileView =APP_UTILITIES.isMobile();
    }

    mounted() {
      this.isMobile();
      window.addEventListener('resize', APP_UTILITIES.debounce(this.isMobile));
      this.renderComponent();
      const dlObject = {'AccountName': undefined ,'AccountType': undefined, 'UserRole' : this.currentRoleId};
      APP_UTILITIES.setCookie('dlUserRole',JSON.stringify(this.currentRoleId), 1);
      this.$emit('updateDataLayer', dlObject);
      this.getAccountsDetails(this.roleId);    
      if (APP_UTILITIES.getFilteredColumns(`${this.authKey}-AccountsSettingsData`)) {
        let AccountsSettingsData:any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-AccountsSettingsData`);
        AccountsSettingsData = JSON.parse(AccountsSettingsData);
        tableStore.mutateFilteredObject(AccountsSettingsData);
      }       
      if (APP_UTILITIES.getFilteredColumns(`${ this.authKey}-AccountsResizeData`)) {
        let accountsResizeData:any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-AccountsResizeData`);
        accountsResizeData = JSON.parse(accountsResizeData);
        tableStore.mutateResizeObject(accountsResizeData);
      }       
      this.displayColumn = tableStore.filteredObject;        
    }

    renderComponent(){
      if (this.$route.path.includes('/users')) {
        this.componentRender = 'manage-users';
      }
      else {
        this.componentRender = 'account-table';
      }
    }

    /**
     * Show reset password screen
     * 
     * @param user 
     */

    showResetPasswordPopup(user: {userId:number; name: string|null}){
      this.selectedUser = user;
      if (UIkit.modal('#reset-password-popup')) {
        UIkit.modal('#reset-password-popup').show();
      }
    }

    /**
     * Reset Password API call
     * 
     * @param password 
     */
    resetPassword(password:string){
      const payload ={ selectedIds: [this.selectedUser.userId], password: password };
      const progressBar: any=this.$refs.resetPassword;
      progressBar.isValid = false;
      bulkResetPassword(payload).then((response:any)=>{
        if(response.status === APP_CONST.RESPONSE_200){
          if(progressBar){
            progressBar.apiResponseCode = APP_CONST.RESPONSE_200;
            progressBar.isValid = true;
          }                
          Vue.prototype.$eventHub.$emit(APP_CONST.RESET_PASSWORD_INDICATOR_STRIP, { component: APP_CONST.RESET_PASSWORD, stripShow: APP_CONST.TRUE, attendanceStripShow: false, sendInvitationStripShow: true, stripText: APP_CONST.PASSWORD_RESET_CONFIRMATION_TEXT, isMobileView: false });
          this.resetPasswordTimer = setTimeout(() => {
            Vue.prototype.$eventHub.$emit(APP_CONST.RESET_PASSWORD_INDICATOR_STRIP, { component: APP_CONST.RESET_PASSWORD, stripShow: false, attendanceStripShow: false, sendInvitationStripShow: true, stripText: '', isMobileView: false });
          }, APP_CONST.AUTO_SAVE_INTERVAL);
        }
        else if((response.status === APP_CONST.RESPONSE_400) && progressBar) {
          progressBar.apiResponseCode = APP_CONST.RESPONSE_400;
        }
        else if((response.status === APP_CONST.RESPONSE_500) && progressBar) {
          progressBar.apiResponseCode = APP_CONST.RESPONSE_500;
        }
      });
    }

    /**
     * Hide orange banner
     * 
     */
    hideOrangeBar(){
      if(this.resetPasswordTimer>0){
        clearTimeout(this.resetPasswordTimer);
        this.resetPasswordTimer = 0;
        Vue.prototype.$eventHub.$emit(APP_CONST.RESET_PASSWORD_INDICATOR_STRIP, {
          component: APP_CONST.RESET_PASSWORD,
          stripShow: false,
          attendanceStripShow: false, 
          sendInvitationStripShow: true,
          isMobileView: false
        });
      }
    }

    closepopup(e: string) {
      this.pointerEventNone = APP_CONST.FALSE;
      this.$emit(APP_CONST.NO_ACTION, false);
      if (UIkit.offcanvas(`#${e}`)) {
        UIkit.offcanvas(`#${e}`).hide();
      }
    }

    closeModal(e: {goToHome: boolean; modal: string} = {goToHome: false, modal: ''}) {
      this.pointerEventNone = APP_CONST.FALSE;
      const modal = UIkit.modal(`#${e.modal}`);
      /* istanbul ignore if */
      if(modal) {
        modal.hide().then(() => {
          if(e.goToHome) {
            modal.$destroy(true);
            this.$router.push('/home');
          } 
        });
      }
    }

    edit(userDt: any) {
      if(userDt.id === 'back') {
        this.user = JSON.parse(JSON.stringify(userDt.user));
        if(UIkit.offcanvas('#read-user')){
          UIkit.offcanvas('#read-user').show();
        }
        return;
      }
      /* istanbul ignore if */
      if (userDt && userDt.userRoles && userDt.userRoles.length && userDt.id) {
        this.$emit(APP_CONST.NO_ACTION, true);
        this.user = JSON.parse(JSON.stringify(userDt));
        const dataToEdit: any = {
          createdAt: userDt.createdAt, createdBy: userDt.createdBy, email: userDt.email,emailConfirmed: userDt.emailConfirmed,
          firstName: userDt.firstName, forgotPasswordGuid:userDt.forgotPasswordGuid,id: userDt.id, lastName: userDt.lastName, 
          phoneNumber: userDt.phoneNumber, status: userDt.status,
          personalInvitationNote: userDt.personalInvitationNote, userRoles: userDt.userRoles,
          roleName: this.getUserRole(userDt.userRoles[0].roleId)
        };
        /* istanbul ignore next */
        const higherRole = Math.min(...userDt.userRoles.map((user: roles) => user.roleId));
        if(higherRole < APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN) {
          this.getUserbyId(dataToEdit.id, dataToEdit);
        }
        else {
          this.getUserInfo({userId: userDt.id});
        }
      }
      else {
        const email = userDt.email;
        const status = {
          id: 4,
          name: 'InvitePending'
        };           
        resendInvite(email).then((res) => {
          /* istanbul ignore else */
          if(res.status === APP_CONST.RESPONSE_200) {
            this.manage_list[userDt.index]['status']= status;
            this.inviteText = APP_CONST.USERS_INVITATION_BODY;
            UIkit.modal('#modal-invitation-sent').show();
          }
        });
      }
    }

    getUserbyId(id: number, dataToEdit: any) {
      getUserDetailsResponseObj(id).then((res: any) => {
        /* istanbul ignore else */
        if(res.status === APP_CONST.RESPONSE_200) {
               
          const nonBxDetails : any = {};
          const index = 0;
          res.data.userRoles.forEach((item: any, id: number) => {
            /* istanbul ignore else */
            if (item.roleId === dataToEdit.userRoles[0].roleId) {
              nonBxDetails.roleId = item.roleId;
              this.CheckNonBxUsers(item, nonBxDetails, dataToEdit);
            }
          });
          dataToEdit.userRoles[0] = {...dataToEdit.userRoles[0], ...nonBxDetails};
          dataToEdit.inactiveDate= (res.data.inactiveDate!=undefined && res.data.inactiveDate!=null && res.data.inactiveDate!='')
            ?res.data.inactiveDate
            :new Date();
          this.userDetails = JSON.parse(JSON.stringify(dataToEdit));
          this.pointerEventNone = APP_CONST.TRUE;
          UIkit.offcanvas('#add-update-user').show();
        }
      });
    }

    private CheckNonBxUsers(item: any, nonBxDetails: any, dataToEdit: any) {
      /* istanbul ignore else */
      if (item.accountId && (item.accountId > APP_CONST.ZERO)) {
        nonBxDetails.accountId = item.accountId;
        nonBxDetails.isPrimaryAccountAdmin = item.isPrimaryAccountAdmin;
        dataToEdit.accountName = 'Select Account';
      }
      /* istanbul ignore else */
      if (item.programId && (item.programId > APP_CONST.ZERO)) {
        nonBxDetails.programId = item.programId;
        dataToEdit.programName = 'Select Program';
      }
      /* istanbul ignore else */
      if (item.siteId && (item.siteId > APP_CONST.ZERO)) {
        nonBxDetails.siteId = item.siteId;
        dataToEdit.siteName = 'Select Site/Session';
      }
    }

    /* istanbul ignore next */
    getUserInfo(user: {userId: number}) {
      getUserInfomartionByUserId(user.userId).then((res: any) => {
        const userDetails = res.data;
        userDetails.phoneNumber = APP_UTILITIES.formatNumber(userDetails.phoneNumber);
        this.pointerEventNone = APP_CONST.TRUE;
        const higherRole = Math.min(...userDetails.userRoles.map((userDt: roles) => userDt.roleId));
        if(higherRole >= APP_CONST.ROLE_TYPE_ACCOUNT_ADMIN) {
          manageListStore.partnerUserData(userDetails).then(() => {
            if(manageListStore.userInfo.userRole.length) {
              const [role] = manageListStore.userInfo.userRole;
              getProgramByAccount(role.accountId).then((progListRes) => {
                const userData = manageListStore.userInfo;
                if(progListRes.status === APP_CONST.RESPONSE_200){
                  userData.userRole.forEach((role: any) => {
                    const program = progListRes.data.find((prog: any) => prog.id === role.programId);
                    if(program) {
                      role['disableState'] = program.status;
                    }
                  }); 
                }
                this.partnerUserDetails = userData;
                UIkit.offcanvas('#add-update-partner').show();
              });
            }
            else {
              this.partnerUserDetails = manageListStore.userInfo;
              UIkit.offcanvas('#add-update-partner').show();
            }
          });
        }
        else {
          this.edit(userDetails);
        }
      });
    }

    /* istanbul ignore next */
    openReadUserModal(user: any) {
      /* istanbul ignore else */
      if (user && user.role && user.role.userId) {
        getUserInfomartionByUserId(user.role.userId).then((res: any) => {
          user=res.data;
          const [role] = user.userRoles;
          user.userRoles.forEach((element : any)=> {
            if((element.roleId == APP_CONST.SEVEN) || (element.roleId == APP_CONST.NINE)){
              element.userRoleClassrooms.forEach((ele:any) => {
                ele['className'] = '';
              });
            }
          });
          if(user.userRoles.length === 1 && user.userRoles[0].roleId === 4) {
            this.filteredUserRoles(user);
            return;
          }
          getProgramByAccount(role.accountId).then((progListRes) => {
            if(progListRes.status === APP_CONST.RESPONSE_200){
              user.userRoles.forEach((role: any) => {
                const program = progListRes.data.find((prog: any) => prog.id === role.programId);
                if(program) {
                  role['disableState'] = program.status;
                }
              }); 
            }
            this.filteredUserRoles(user);
          });
        });
      }
    }

    /* istanbul ignore next */
    filteredUserRoles(user: any) {
      user.phoneNumber = APP_UTILITIES.formatNumber(user.phoneNumber);
      this.user = JSON.parse(JSON.stringify(user));
      this.pointerEventNone = APP_CONST.TRUE;
      if(UIkit.offcanvas('#read-user')) {
        UIkit.offcanvas('#read-user').show();
        this.user.userRoles.forEach((role : any) => {
          if(role.siteId && role.programId){
            getClassroomsBySite(role.programId, role.siteId).then((reponse:any) => {
              if(reponse.status === APP_CONST.RESPONSE_200){   
                reponse.data.forEach((classFromClasses : any) => {
                  role.userRoleClassrooms.filter((classFromList : any) => {
                    if (classFromClasses.id == classFromList.classRoomId ) {
                      classFromList.className = classFromClasses.classroomName;
                    }
                  });
                });
              }
            });
          }
        });
      }
    }
    
    resetHeaders(type:string){
      if(type === 'account-header'){
        // this.account_header.map((item:any)=>{
        //     if(item.value=== 'accountName') {
        //         item.sortField = "accountName";
        //         item.order = 1;
        //     } else {
        //         item.order = 0;
        //     }
        // });
      }
      else {
        this.manage_users_header.map((item:any)=>{
          if(item.value=== 'firstName') {
            item.sortField = 'firstName';
            item.order = 1;
          }
          else {
            item.order = 0;
          }
        });
      }
    }
    
    beforeMount() {
      manageListStore.getAllPrograms();
      const selectedProgramStatus: any = {filter: 0,name: 'All Program'};
      programListStore.mutateProgramStatus(selectedProgramStatus);

      /* istanbul ignore else */
      if (this.$route.path === '/users') {
        this.tabSelected = this.roleId < 3
          ? 'bellxcel-users'
          : 'partner-users';
        this.manage_users_header = this.roleId == 4
          ? APP_CONST.MANAGE_USERS_PROGRAM_HEADER
          :APP_CONST.MANAGE_USERS_BX_HEADER;
        this.manage_key=this.roleId == 4
          ? APP_CONST.MANAGE_PROGRAM_KEY
          : APP_CONST.MANAGE_BX_KEY;
        this.resetHeaders('manage-users-header');
        this.isSearching=false;
        this.authKey = APP_UTILITIES.authTokenKeyToManage()
          ? APP_UTILITIES.authTokenKeyToManage()
          : '';   
        if(this.authKey){
          this.getSortingDefaultManageUser();
        }
      }
      else {
        this.isSearching=false;
        this.authKey = APP_UTILITIES.authTokenKeyToManage()
          ? APP_UTILITIES.authTokenKeyToManage()
          : ''; 
        this.clearSelection();
        this.accountListFullData().then(() => {
          this.accountList().then(()=>{
            this.initialTotalItems = this.total_items;
          });
        });
      }
      window.addEventListener('keyup', this.escapeEventFired);
    }

    /* istanbul ignore next */
    beforeDestroy () {
      window.removeEventListener('keyup', this.escapeEventFired);
    }

    escapeEventFired(event : any){
      /* istanbul ignore else */
      if (event.which === 27) {
        this.pointerEventNone = false;
        this.$emit(APP_CONST.NO_ACTION, this.pointerEventNone);
      }
    }

    openAddUserForm(){
      this.pointerEventNone=true;
      this.$emit(APP_CONST.NO_ACTION, this.pointerEventNone);
    }
    callHover(value:string, event: any, id?: number){
      this.hoverText = value;
      /* istanbul ignore else */
      if (id) {
        this.hoverId = id;
      }
      const boundBox = event && event.target && event.target.getBoundingClientRect();
      const coordX = boundBox.left;
      const coordY = boundBox.top;
      this.hoverStyleObj = {
        position: 'fixed',
        top:(coordY + 45).toString() + 'px',
        left:(coordX + 15).toString() + 'px'
      };
    }

    callHoverOut(){
      this.hoverText = '';
      this.hoverId = 0;
    }

    setSortingDefaultManageUser():void{
      let createKey;
      if(this.tabSelected=='bellxcel-users'){
        createKey = this.authKey + '_manage_user_sort';
      }
      else{
        createKey = this.authKey + '_manage_partner_user_sort';
      }
      const sortOrder = (this.manageUserPayload.sortDir == 1 || this.manageUserPayload.sortDir == 0)
        ? '1'
        : '2';
      APP_UTILITIES.setSortingOnSession(createKey, this.manageUserPayload.sortField, sortOrder);
    }

    getSortingDefaultManageUser():void{
      let createKey;
      if(this.tabSelected=='bellxcel-users'){
        createKey = this.authKey + '_manage_user_sort';
      }
      else{
        createKey = this.roleId < 3
          ? this.authKey + '_manage_partner_user_sort'
          : this.authKey + '_manage_program_user_sort';
      }
      let sortVal:string | null = '';
      /* istanbul ignore next */
      if(APP_UTILITIES.getSortingOnSession(createKey)){
        sortVal = APP_UTILITIES.getSortingOnSession(createKey);
        const sortObj:{key: string; dir: string} = sortVal
          ? JSON.parse(sortVal)
          : {};
        this.manageUserPayload.sortField = sortObj.key;
        this.manageUserPayload.sortDir = sortObj.dir;
        this.manageList({ page : 1, sortField: this.manageUserPayload.sortField, sortDir: this.manageUserPayload.sortDir });
        /* istanbul ignore else */
        if(this.manageUserPayload.sortField){
          this.manage_users_header.forEach((element:any) => {
            if(element.value == this.manageUserPayload.sortField){
              element.order= parseInt(this.manageUserPayload.sortDir);
            }
            else{
              element.order = 0;
            }
          });
        }
      }
      else{
        this.manageList({ page : 1, sortField: '', sortDir: 1 });
        this.setSortingDefaultManageUser();
      }
    }

    accountsVisible (item:string) {
      const currentAccountName = item.toLowerCase();
      const currentInput = this.accountFilterInput.toLowerCase();
      return currentAccountName.includes(currentInput);
    }

    async filterListByAccount(accountId: number, accountName: string, state?: boolean){
      this.filteredByAccount = accountName;
      this.filteredByAccountId=accountId;
      const payload = APP_CONST.MANAGE_PAYLOAD;
      payload.page = APP_CONST.ONE;
      payload.id = accountId;
      payload.sortField = 'firstName';
      payload.sortDir = APP_CONST.ONE;
      payload.roleType = APP_CONST.TWO;        
      this.manageList({ page: 1, sortField: '', sortDir: 1 });        
      this.CheckManageListAPIRepsonse(state, payload);
      this.manageUserPayload = JSON.parse(JSON.stringify(payload));
      this.setSortingDefaultManageUser();
      this.callHoverOut();
    }

    
    getAccountsDetails(roleId:number){
      if(roleId < 3){
        getAccounts().then((res:any) => {
          /* istanbul ignore else */
          if(res.status === APP_CONST.RESPONSE_200){
            const data = res.data;
            this.allAccounts = data as IAccount[];
          }
        });
      }
      else if(roleId == 4){
        const storedAccountId:any = APP_UTILITIES.getCookie('user_role');
        getProgramByAccount(storedAccountId && JSON.parse(storedAccountId)[0].accountId).then((res:any) => {
          /* istanbul ignore else */
          if(res.status === APP_CONST.RESPONSE_200){  
            const data = res.data;
            this.allAccounts = data as IAccount[];
          }
        });

      }
    }

    resetAccountDropdown(){
      this.filteredByAccountId=APP_CONST.ZERO;
      this.filteredByAccount = '';
      this.accountFilterDropdown = false;
      this.accountFilterInput = '';
    }

    /* istanbul ignore else */
    removeUser(userDt: any) {
      /* istanbul ignore else */
      if (userDt && userDt.role && userDt.role.userId) {            
        deleteUser(userDt.role.userId).then((res) => {
          /* istanbul ignore else */
          if(res.status === APP_CONST.RESPONSE_200) {                    
            UIkit.notification.closeAll();
            UIkit.notification({
              message: 'User removed successfully.',
              status: 'primary',
              pos: 'top-center',
              timeout: 5000
            });
            this.getSortingDefaultManageUser();                    
          }
        });
      }
    }
    getUserRole(id: number){
      return APP_UTILITIES.getUserRole(id);
    }

    closeSearchPopup() {
      const filterSearch = this.$refs.filterPanels as any;
      /* istanbul ignore else */
      if(filterSearch){
        filterSearch.hide();
      }
    }
    setPage(page: number){
      this.page=page;
    }

    /* istanbul ignore next */
    async updateAccountStatus(event: { accountId: number; status: number }) {
      await accountListStore.UpdateAccount({ accountId: event.accountId, status: event.status });
      /* istanbul ignore else */
      if (accountListStore.UpdatedAccountRes.status === APP_CONST.RESPONSE_200) {
        const temp_account_list = [...this.account_list];
        temp_account_list.find((el: any) => el.accountId === event.accountId).status = event.status;
        this.account_list = [...temp_account_list];
      }
    }

    filterColumns(displayColumn:any){
      let isSelected = false;
      displayColumn && displayColumn.length && displayColumn.forEach((column: any) => {
        if(column.display) {
          isSelected = true;
        }
      });
      this.displayAll = isSelected;
      this.clearAllColumns(this.displayAll);
      tableStore.mutateFilteredObject(displayColumn);
      tableStore.mutateSettingsData(displayColumn);
      if (APP_UTILITIES.getFilteredColumns(`${ this.authKey}-AccountsResizeData`)) {
        let accountsResizeData: any = APP_UTILITIES.getFilteredColumns(`${this.authKey}-AccountsResizeData`);
        accountsResizeData = JSON.parse(accountsResizeData);
        tableStore.mutateResizeObject(accountsResizeData);
      }  
    }

    clearAllColumns(displayAll:boolean){
      let searchObj:SearchObj = {
        'searchField' : accountListStore.accountPayload && accountListStore.accountPayload.searchField
          ? accountListStore.accountPayload.searchField
          : '',
        'search' : accountListStore.accountPayload && accountListStore.accountPayload.search
          ? accountListStore.accountPayload.search
          : '', 
      };
      let sortObjc:SortObjc = {
        'sortField' : accountListStore.accountPayload && accountListStore.accountPayload.sortField
          ? accountListStore.accountPayload.sortField
          : '',
        'sortDir' : accountListStore.accountPayload && accountListStore.accountPayload.sortDir
          ? accountListStore.accountPayload.sortDir
          : 0
      };
      const isSearchFieldFixed = this.account_header_fixed.map((e:any)=> {
        return e.value; 
      }).indexOf(searchObj.searchField);
      const isSortFieldFixed = this.account_header_fixed.map((e:any)=> {
        return e.value; 
      }).indexOf(sortObjc.sortField); 
      if(!displayAll && isSearchFieldFixed == -1){
        searchObj={
          'searchField': accountListStore.accountPayload && accountListStore.accountPayload.searchField == APP_CONST.ACCOUNT_LIST_NAME
            ? accountListStore.accountPayload.searchField
            : '',
          'search' : accountListStore.accountPayload && accountListStore.accountPayload.searchField == APP_CONST.ACCOUNT_LIST_NAME
            ? accountListStore.accountPayload.search
            : '',
        };
        accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        this.clearSelection();
        this.accountListFullData().then(() => {
          this.accountList();
        });
        Object.assign(this.accountsSearchObj, { accountStatus: '', contractedScholars: '', contractedScholarsUsed: '', programCount: '', primaryAccountAdminFullName: '', bxPartnerLeadFullName: '', bxSuccessLeadFullName: '' });    
      }
      if(!displayAll && isSortFieldFixed == -1){
        sortObjc={
          'sortField': accountListStore.accountPayload && accountListStore.accountPayload.sortField == APP_CONST.ACCOUNT_LIST_NAME
            ? accountListStore.accountPayload.sortField
            : '',
          'sortDir': accountListStore.accountPayload && accountListStore.accountPayload.sortField == APP_CONST.ACCOUNT_LIST_NAME
            ? accountListStore.accountPayload.sortDir
            : APP_CONST.ZERO,
        };
        accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload,searchField: searchObj.searchField, search: searchObj.search, sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
        this.clearSelection();
        this.accountListFullData().then(() => {
          this.accountList();
        });
        Object.assign(this.accountsSearchObj, { accountStatus: '', contractedScholars: '', contractedScholarsUsed: '', programCount: '', primaryAccountAdminFullName: '', bxPartnerLeadFullName: '', bxSuccessLeadFullName: '' });    
      }
    }


    filterDisplayColumn(column:any){
      let searchObj:SearchObj = {
        'searchField' : accountListStore.accountPayload && accountListStore.accountPayload.searchField
          ? accountListStore.accountPayload.searchField
          : '',
        'search' : accountListStore.accountPayload && accountListStore.accountPayload.search
          ? accountListStore.accountPayload.search
          : '', 
      };
      let sortObjc:SortObjc = {
        'sortField' : accountListStore.accountPayload && accountListStore.accountPayload.sortField
          ? accountListStore.accountPayload.sortField
          : '',
        'sortDir' : accountListStore.accountPayload && accountListStore.accountPayload.sortDir
          ? accountListStore.accountPayload.sortDir
          : 0
      };
      if(!column.display){
        if(column.value == accountListStore.accountPayload.searchField){
          searchObj={
            'searchField': '',
            'search' : '',
          };
          accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload ,searchField: searchObj.searchField, search: searchObj.search});
          accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload ,searchField: searchObj.searchField, search: searchObj.search});
          this.clearSelection();
          this.accountListFullData().then(() => {
            this.accountList();
          });
        }
        if(column.value == accountListStore.accountPayload.sortField){
          sortObjc={
            'sortField':'',
            'sortDir': 0,
          };
          accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload ,sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
          accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload ,sortField: sortObjc.sortField, sortDir: sortObjc.sortDir,});
          this.clearSelection();
          this.accountListFullData().then(() => {
            this.accountList();
          });
        }
        this.accountsSearchObj[column.value]= '';

      }
    }


    navigateToProgram(data: any) {
      const accountId: string = data.accountId.toString();
      APP_UTILITIES.setCookie('accountId', accountId, 1);
      setTimeout(() => {
        const query = { accountId: data.accountId, accountName: data.accountName };
        if(data.accountName){
          APP_UTILITIES.setCookie('accountName', data.accountName, 1);
        }
        tableStore.QueryForProgram(query);
        this.$store.dispatch('globalModule/assignAccountId', data.accountId);
        programList.mutateProgramsPayload(JSON.parse(JSON.stringify(APP_CONST.PROGRAM_PAYLOAD)));
        this.$router.push('/account/programs');
      }, 0);
    }

    sortData(sortObj: { sortField: string; sortDirection: number }) {
      accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload,page:1, sortField: sortObj.sortField, sortDir: sortObj.sortDirection});
      this.total_items = this.isLoaderVisible
        ? -1
        : this.total_items;
      this.clearSelection();
      accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload,page:1, sortField: sortObj.sortField, sortDir: sortObj.sortDirection});
      this.accountListFullData().then(() => {
        this.accountList();
      });
    }

    searchData(search:{searchField: string; isExactMatch: boolean}) {
      this.search = '';
      accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload, page: 1, searchField : this.accountsSearchObj[search.searchField]
        ? search.searchField
        : '', search: this.accountsSearchObj[search.searchField]
        ? this.accountsSearchObj[search.searchField]
        : '', isExactMatch: search.isExactMatch});
      this.total_items = this.isLoaderVisible
        ? -1
        : this.total_items;
      this.clearSelection();
      accountListStore.mutateAccountsPayloadFull({ ...accountListStore.fullAccountsPayload, page: 1, searchField : this.accountsSearchObj[search.searchField]
        ? search.searchField
        : '', search: this.accountsSearchObj[search.searchField]
        ? this.accountsSearchObj[search.searchField]
        : '', isExactMatch: search.isExactMatch});
      this.accountListFullData().then(() => {
        this.accountList();
      });
    }

    presistResize(resize: { column: any; maxWidth: any; minWidth: any }) {
      tableStore.mutateResizeData(resize);
    }

    getDataForPage(page: number) {
      accountListStore.mutateAccountPayload({ ...accountListStore.accountPayload, page: page });
      this.accountList();
    }

    /* istanbul ignore next */
    destroyed() {
      tableStore.mutateSelectAll(false);
      this.clearSelection();
      this.hideOrangeBar();
    }
}