




























































































































































import AccountListComponent from '@/components/accountlistcomponent/AccountListComponent';
export default AccountListComponent;
